// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap');
@import './variables';
@import './mixins';

h1 {
  @include type-title-l;
}

h2 {
  @include type-title-m;
  
}

h3 {
  @include type-title-s;
}

p {
  @include type-body;
}

a {
  @include type-link;
}

h1, h2, h3, h4, p, a, li, span {
  color: $color-white;
}

.is-light-mode {
  h1, h2, h3, h4, p, a, li, span {
    color: $color-darkGrey;
  }
}