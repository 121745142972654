$font-path: "../assets/fonts" !default;

/*
** Black
*/

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('#{$font-path}/BrandonGrotesque-Black.woff2') format('woff2'),
//       url('#{$font-path}/BrandonGrotesque-Black.woff') format('woff'),
//       url('#{$font-path}/BrandonGrotesque-Black.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

/*
** Bold
*/

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('#{$font-path}/BrandonGrotesque-Bold.woff2') format('woff2'),
      url('#{$font-path}/BrandonGrotesque-Bold.woff') format('woff'),
      url('#{$font-path}/BrandonGrotesque-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('#{$font-path}/BrandonGrotesque-BoldItalic.woff2') format('woff2'),
//       url('#{$font-path}/BrandonGrotesque-BoldItalic.woff') format('woff'),
//       url('#{$font-path}/BrandonGrotesque-BoldItalic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

/*
** Medium
*/

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('#{$font-path}/BrandonGrotesque-Medium.woff2') format('woff2'),
      url('#{$font-path}/BrandonGrotesque-Medium.woff') format('woff'),
      url('#{$font-path}/BrandonGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('#{$font-path}/BrandonGrotesque-MediumItalic.woff2') format('woff2'),
//       url('#{$font-path}/BrandonGrotesque-MediumItalic.woff') format('woff'),
//       url('#{$font-path}/BrandonGrotesque-MediumItalic.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }

/*
** Regular
*/

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('#{$font-path}/BrandonGrotesque.woff2') format('woff2'),
      url('#{$font-path}/BrandonGrotesque.woff') format('woff'),
      url('#{$font-path}/BrandonGrotesque.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('#{$font-path}/BrandonGrotesque-RegularItalic.woff2') format('woff2'),
//       url('#{$font-path}/BrandonGrotesque-RegularItalic.woff') format('woff'),
//       url('#{$font-path}/BrandonGrotesque-RegularItalic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

/*
** Light
*/

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('#{$font-path}/BrandonGrotesque-Light.woff2') format('woff2'),
      url('#{$font-path}/BrandonGrotesque-Light.woff') format('woff'),
      url('#{$font-path}/BrandonGrotesque-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('#{$font-path}/BrandonGrotesque-LightItalic.woff2') format('woff2'),
//       url('#{$font-path}/BrandonGrotesque-LightItalic.woff') format('woff'),
//       url('#{$font-path}/BrandonGrotesque-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

/*
** Thin Italic
*/

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('#{$font-path}/BrandonGrotesque-ThinItalic.woff2') format('woff2'),
//       url('#{$font-path}/BrandonGrotesque-ThinItalic.woff') format('woff'),
//       url('#{$font-path}/BrandonGrotesque-ThinItalic.ttf') format('truetype');
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

/*
** DIN Condensed
*/

@font-face {
  font-family: 'DIN Condensed';
  src: url('#{$font-path}/DINCondensed-Bold.woff2') format('woff2'),
      url('#{$font-path}/DINCondensed-Bold.woff') format('woff'),
      url('#{$font-path}/DINCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}