@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-l;
  height: 80px;
  width: 100%;
  background-color: rgba($color-black, 0);
  will-change: transform;
  z-index: $layer-9;
  
  @include device-m {
    padding: 0 $spacing-ml;
    transition-duration: 0ms;
  }
  
  .logo {
    height: 35px;
    margin-left: 5px;
    cursor: pointer;
    @include device-m {
      height: 30px;
    }
    :global {
      .base-line {
        // transition: fill $transition-normal ease-in-out;
        will-change: fill, stroke;
        fill: #fff;
      }
    }
  }
  
  &.inTop {
    position: absolute;
    // transform: translate3d(0, -80px, 0);
    background-color: rgba($color-black, 0);
  }
  
  &.goingDown {
    position: fixed;
    transition: transform $transition-normal $easeOutCubic;
    background-color: rgba($color-black, 0.8);
    &.lightMode {
      background-color: rgba($color-white, 0.9);
    }
  }
  
  &.goingUp {
    position: fixed;
    transition: transform $transition-normal $easeOutCubic;
    background-color: rgba($color-black, 0.8);
    &.lightMode {
      background-color: rgba($color-white, 0.9);
    }
  }

  .burgerMenu {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 20px;

    .line {
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      transform-origin: center center;
      background-color: $color-white;
      transition: opacity $transition-fast $easeInOutCubic, transform $transition-fast $easeInOutCubic;
      opacity: 1;

      &.top {
        top: 0
      }

      &.middle1,
      &.middle2 {
        top: 50%;
        margin-top: -1px;
      }

      &.bottom {
        bottom: 0
      }
    }

    &.open {
      .line {
        &.top,
        &.bottom {
          opacity: 0;
        }
        &.top {
          transform: translate3d(0, 10px, 0) scale(0);
        }
        &.bottom {
          transform: translate3d(0, 10px, 0) scale(0);
        }
        &.middle1 {
          transform: rotate(45deg);
        }
        &.middle2 {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.lightMode {
    .burgerMenu {
      .line {
        background-color: $color-darkGrey;
      }
    }
  }

  

  &.lightMode {
    .logo {
      :global {
        .base-line {
          fill: $color-darkGrey;
        }
      }
    }
  }

}