@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.mobileMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  background-color: $color-background;
  transform-origin: center left;
  transition: transform $transition-normal $easeInOutCubic;
  padding-top: $spacing-xl;

  &.lightMode {
    background-color: $color-white;
  }

  &.menuOpen {
    transform: translate3d(-100%, 0, 0);
  }

  .socials {
    position: relative;
    width: 100%;
    margin-top: 100px;
    padding: 0 $spacing-ml;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    a {
      display: inline-block;
      padding: $spacing-s;
      margin: 0 $spacing-s;
      svg {
        width: 30px;
        height: 30px;
        &.medium {
          transform: scale(1.1)
        }
        &.discord {
          transform: scale(1.4)
        }
        &.telegram {
          transform: scale(1.7)
        }
        path {
          fill: darken($color: $color-white, $amount: 20);;
          // transition: fill $transition-normal ease-in-out;
        }
      }
    }
  }

  &.lightMode {
    .socials {
      a {
        svg {
          path {
            fill: $color-darkGrey;
          }
        }
      }
    }
  }
}