$primary-color: #282c34;
$secondary-color: #61dafb;

$typeface: 'Brandon Grotesque', sans-serif;

$text-size-xl: 68px;
$text-size-l: 52px;
$text-size-ml: 30px;
$text-size-m: 22px;
$text-size-s: 16px;
$text-size-xs: 12px;

$color-white: #ffffff;
$color-black: #000000;
$color-darkGrey: #333333;
$color-pink: #ff87b5;

$color-background: $color-black;

$spacing-xl: 60px;
$spacing-l: 40px;
$spacing-ml: 30px;
$spacing-m: 20px;
$spacing-s: 10px;

$layer-0: 0;
$layer-1: 100;
$layer-2: 200;
$layer-3: 200;
$layer-9: 999;


$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutQuint: cubic-bezier(0.22, 1, 0.36, 1);

$transition-fast: 0.25s;
$transition-normal: 0.5s;
