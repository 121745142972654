@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.imageSequence {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 100%;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    &.active {
      display: block;
    }
  }
}