@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.screen {
  position: absolute;
  width: 100vw;
  // height: 100vh;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.column {
    flex-direction: column;
    justify-content: center;
  }

  &.fluid {
    position: relative;
    // margin: 20vh auto;
  }

  &.secondPart {
    top: 550vh;
    height: 700vh;
    flex-direction: column;
    justify-content: space-between;
    
    &.isMobile{
      @include device-m {
        height: 500vh;
      }
    }
  }
  
  .backgroundGradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color-black, 0.6);
    mask-image: linear-gradient(to top, transparent 0%, black 25%, black 75%, transparent 95%);
    display: none;
    @include device-m {
      display: block;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 45%;
    margin-left: 5%;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 0 0 $spacing-m;
    // pointer-events: none;



    @include device-l {
      width: 55%;
    }

    @include device-m {
      width: 100%;
      margin: 0;
      padding: 0 $spacing-ml;
    }
  
    p {
      max-width: 85%;
      @include device-m {
        max-width: 100%;
      }
    }

    &.wide {
      width: 70%;
      p {
        max-width: 80%;
        @include device-l {
          max-width: 80%;
        }
      }
      @include device-m {
        width: 100%;
        p {
          max-width: 100%;
        }
      }
    }
  }
  
  &.alignRight {
    // align-items: flex-end;
    justify-content: flex-end;
    .content {
      width: 50%;
      margin-left: 0%;
      padding: 0 $spacing-l 0 0;

      @include device-l {
        width: 60%;
      }

      @include device-m {
        width: 100%;
        margin: 0;
        padding: 0 $spacing-ml;
      }


      &.prizes1 {
        padding-left: 8vw;
        @include device-m {
          padding-left: 4vw;
        }
        @include device-m {
          padding-left: $spacing-ml;
        }
      }
    }
  }
}

.gradient1 {
  color: $color-white;
  background: -webkit-linear-gradient(90deg, #{$color-white}, #{$color-pink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient2 {
  color: $color-white;
  background: -webkit-linear-gradient(90deg, #{$color-pink}, #{$color-white});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.big1 {
  position: absolute;
  display: inline-block;
  font-size: 250px;
  font-weight: 300;
  text-align: center;
  left: 50%;
  width: 100px;
  margin-left: -56px;
  // margin-top: -30px;
  line-height: 1;
  top: 15vh;

  @include device-l {
    left: 60%;
  }

  @include device-m {
    font-size: 150px;
    // opacity: 0.6;
    top: 5vh;
    left: 30%;
  }
}

.alignRight {
  .big1 {
    bottom: 15vh;
    top: auto;

    @include device-l {
      left: 40%;
    }
    @include device-m {
      bottom: 5vh;
      top: auto;
      left: 70%;
    }
  }
}

.separator {
  position: absolute;
  width: 200vw;
  // height: 100vh;
  top: 400vh;
  left: -50vw;
  transform-origin: center top;
  // background-color: $color-black;
  border-top: 1px solid $color-white;
  transform: rotate(-10deg);
  // z-index: $layer-1;

  .plusIcon {
    position: absolute;
    width: 120px;
    height: 120px;
    top: 0;
    left: 50%;
    margin: -60px 0 0 -60px;
    transform: rotate(10deg);

    @include device-m {
      width: 80px;
      height: 80px;
      margin: -40px 0 0 -40px;
    }

    path {
      stroke-width: 2px;
      fill: $color-black;
      stroke: $color-white;
      // transition: fill $transition-normal ease-in-out;
    }
  }

  &.isLightMode {
    background-color: $color-white;
    border-top: 1px solid $color-black;
    .plusIcon {
      path {
        fill: $color-white;
        stroke: $color-black;
      }
    }
  }
}

.specialI {
  text-transform: lowercase;
  color: $color-white;
  background: -webkit-linear-gradient(90deg, #{$color-pink}, #{$color-white});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.videoContainer {
  position: absolute;
  top: 15vh;
  right: 5vw;
  width: 35vw;
  height: 0;
  padding-top: 35vw;
  pointer-events: none;
  
  @include device-l {
    width: 50vw;
    padding-top: 50vw;
    right: -10vw;
    top: 5vh;
  }

  @include device-m {
    // top: 2vh;
    right: 5vw;
    // width: 50vw;
    // margin: 0;
  }
}

.xlTitle {
  position: relative;
  font-size: $text-size-xl * 1.25;
  line-height: 1.1em;
  margin: 0 0 $spacing-s 0;
  letter-spacing: 2px;
  // transition: color $transition-normal ease-in-out;
  will-change: color;
  font-weight: bold;
  margin-left: -80px;
  
  @include device-l {
    margin-left: -60px;
    font-size: $text-size-xl;
  }
  
  @include device-m {
    margin-left: -40px;
    font-size: $text-size-l;
  }

  .bullet {
    position: relative;
    transform: translateY(-16px);
    display: inline-block;
    font-size: 60px;
    @include device-l {
      font-size: 50px;
      transform: translateY(-8px);
    }
    @include device-m {
      font-size: 40px;
      transform: translateY(-8px);
    }
  }

  .count {
    position: absolute;
    top: 0;
    left: 324px;
    @include device-l {
      left: 268px;
    }
    @include device-m {
      left: 208px;
    }
  }

  .super {
    position: relative;
    display: inline-block;
    font-size: $text-size-xl;
    font-size: $text-size-xl;
    font-weight: bold;
    transform: translate3d(0, -20px, 0);
    @include device-l {
      font-size: $text-size-xl * 0.8;
    }
  }
}

.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: $spacing-l;
  // pointer-events: none;
  text-align: center;
  
  @include device-m {
    padding: 0;
  }

  &.row {
    flex-direction: row;
    align-items: flex-end;
    @include device-l {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 50%;
    flex-shrink: 1;
    text-align: left;
    padding: 0 10% 0 0;

    h2:last-child {
      margin-bottom: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include device-l {
      width: 70%;
      padding: 0 10% 0 10%;

      h2:last-child {
        margin-bottom: $spacing-m;
      }
      
      p:last-child {
        margin-bottom: $spacing-m;
      }
    }
    @include device-m {
      width: 100%;
      padding: 0 $spacing-m;
    }
  }

  .left {
    padding: 0 0 0 10%;
    @include device-l {
      padding: 0 0 0 10%;
    }
    @include device-m {
      padding: 0 $spacing-m;
    }
  }
}

.puppyImage {
  position: absolute;
  top: -10vh;;
  right: 10vw;
  width: 15vw;

  :global {
    // path, line, circle {
    //   // transition: fill $transition-normal ease-in-out, stroke $transition-normal ease-in-out;
    //   will-change: fill, stroke;
    // }
    // .cls-1{fill:none;stroke:#f1f2f2;stroke-miterlimit:10;}
  }
  @include device-l {
    top: -20vh;
    right: 0;
    width: 20vw;
  }
  @include device-m {
    // left: 35%;
    right: 40%;
    width: 20vw;
    top: -15vh;
  }
}

:global {
  .is-light-mode {
    :local {
      .puppyImage {
        filter: invert(1);
      }
    }
  }
}

.virtuousCycle {
  width: 90%;
  // height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  padding: $spacing-l;
  // pointer-events: none;
  text-align: center;

  .scrollLabel {
    position: absolute;
    width: 100px;
    left: 50%;
    bottom: -10px;
    opacity: 0.4;
    text-align: center;
    @include type-body;
    margin-left: -50px;
    display: none;
    font-size: $text-size-xs;
    @include device-m {
      display: block;
    }
  }

  @include device-l {
    padding: $spacing-m;
  }
  
  @include device-m {
    padding: $spacing-s $spacing-m $spacing-xl;
    display: block;
    overflow-x: scroll;
    mask-image: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);
  }

  img {
    width: 80%;
    @include device-l {
      width: 100%;
    }
    @include device-m {
      width: 200%;
    }
  }
}

.roadmapWrapper {
  width: 60vw;
  margin-left: 2.5vw;
  margin-top: $spacing-l;
  @include device-l {
    width: 75vw;
    margin-top: $spacing-m;
    // margin-right: 6vw;
  }
  @include device-m {
    width: 98vw;
    margin-left: 2svw;
    // margin-right: 8vw;
  }
}

.roadmapImage {
  :global {
    path, line, circle {
      // transition: fill $transition-normal ease-in-out, stroke $transition-normal ease-in-out;
      will-change: fill, stroke;
    }
    .cls-1,.cls-5{fill:none;stroke-miterlimit:10;}
    .cls-1{stroke:#fff;}
    .cls-2{fill:#fff;}
    .cls-3{font-size:25.51px;fill:#221f20;font-family:DINCondensed-Bold, DIN Condensed;font-weight:700;letter-spacing:0.05em;}
    .cls-4{fill:#f3f3f3;}
    .cls-5{stroke:#fff;stroke-width:0.62px;}
    .cls-6{fill:#fff;}
    .cls-7{fill:#bcbec0;}
  }
}

.logoSimple {
  width: 30px;
  margin-bottom: $spacing-ml;
  path {
    fill: $color-white !important;
    // transition: fill $transition-normal ease-in-out;
    will-change: fill;
  }
  @include device-xl {
    width: 30px;
    margin-bottom: $spacing-m;
  }
}
.buttonWrapper {
  margin: 0 0 $spacing-xl 0;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: $spacing-xl;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-m;

  .link {
    display: inline-block;
    margin: 0 $spacing-l;
    @include type-footer;
    color: $color-white;
    @include device-l {
      margin: 0 $spacing-m;
    }
    @include device-m {
      margin: 0 $spacing-s;
    }
  }
}


:global {
  .is-light-mode {
    :local {
      .screen {
        .backgroundGradient {
          background-color: rgba($color-white, 0.8);
        }
      }
      .logoSimple {
        path {
          fill: $color-black !important;
        }
      }
      .footer {
        .link {
          color: $color-black;
        }
      }
      .roadmapImage {
        :global {          
          .cls-1{stroke:$color-darkGrey;}
          .cls-2{fill:$color-darkGrey;}
          .cls-3{font-size:25.51px;fill:$color-white;font-family:DINCondensed-Bold, DIN Condensed;font-weight:700;letter-spacing:0.05em;}
          .cls-4{fill:$color-darkGrey;}
          .cls-5{stroke:$color-darkGrey;stroke-width:0.62px;}
          .cls-6{fill:$color-darkGrey;}
          .cls-7{fill:$color-darkGrey;}
        }
      }
      .puppyImage {
        :global {
          .cls-1{fill:none;stroke:$color-darkGrey;stroke-miterlimit:10;}
        }
      }
    }
  }
}