@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.menu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include device-m {
    flex-direction: column;
  }

  .links {
    position: relative;
    margin: 0;
    padding: 0;
    display: inline-block;

    @include device-m {
      display: flex;
      flex-direction: column;
    }

    .link {
      position: relative;
      display: inline-block;
      padding: 0 $spacing-m;
      margin: 0 $spacing-m;
      @include type-link;
      color: darken($color: $color-white, $amount: 40);

      @include device-m {
        text-align: center;
        margin: $spacing-s;
        color: darken($color: $color-white, $amount: 20);
      }
      
      &:hover {
        color: darken($color: $color-white, $amount: 0);
      }

      &::after {
        content: ' ';
        position: absolute;
        bottom: -6px;
        width: 30px;
        height: 1px;
        left: 50%;
        margin-left: -15px;
        background-color: $color-white;
        transform: scaleX(0);
        transition: transform $transition-normal $easeInOutCubic;
        will-change: transform;
      }

      &.isActive {
        color: darken($color: $color-white, $amount: 0);
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  &.lightMode {
    .links {
      .link {
        color: lighten($color: $color-darkGrey, $amount: 20);

        @include device-m {
          color: $color-darkGrey;
        }

        &:hover {
          color: lighten($color: $color-darkGrey, $amount: 0);
        }
        &.isActive {
          color: lighten($color: $color-darkGrey, $amount: 0);
        }
        &::after {
          background-color: $color-darkGrey;
        }
      }
    }
  }

  .darkmodeSwitch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 20px;
    border: 1px solid darken($color: $color-white, $amount: 40);
    border-radius: 10px;
    cursor: pointer;
    // transition: border $transition-normal ease-in-out;
    will-change: border;
    margin-left: 30px;

    @include device-m {
      width: 70px;
      height: 30px;
      border-radius: 15px;
      margin: $spacing-xl 0 $spacing-xl 0;
      border-color: darken($color: $color-white, $amount: 40);
    }

    .knob {
      position: absolute;
      display: inline-block;
      width: 14px;
      height: 14px;
      background-color: darken($color: $color-white, $amount: 40);
      border-radius: 7px;
      top: 2px;
      left: 2px;
      // transition: background-color $transition-normal ease-in-out, transform $transition-normal $easeInOutCubic;
      transition: transform $transition-normal $easeInOutCubic;
      will-change: background-color, transform;
      transform: translate3d(0, 0, 0);

      @include device-m {
        width: 24px;
        height: 24px;
        border-radius: 14px;
        background-color: darken($color: $color-white, $amount: 20);
      }
    }

    &:hover {
      .knob {
        background-color: darken($color: $color-white, $amount: 0);
      }
    }

    .switchIcon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      transition: opacity $transition-normal ease-in-out, transform $transition-normal $easeInOutCubic;
      will-change: opacity, transform;

      svg {
        width: 100%;
        height: 100%;
      }

      &.moon {
        right: 6px;
        transform: translate3d(0, 0, 0);
        width: 8px;
        height: 8px;
        top: 5px;
        @include device-m {
          width: 14px;
          height: 14px;
          top: 7px;
          right: 8px;
        }
        svg {
          path {
            fill: darken($color: $color-white, $amount: 40);
            @include device-m {
              fill: darken($color: $color-white, $amount: 20);
            }
          }
        }
      }
      
      &.sun {
        left: 2px;
        width: 20px;
        height: 20px;
        top: -1px;
        opacity: 0;
        transform: translate3d(5px, 0, 0);
        @include device-m {
          width: 30px;
          height: 30px;
          // top: -1px;
          // left: 4px;
        }

        svg {
          path {
            fill: lighten($color: $color-darkGrey, $amount: 20);
            @include device-m {
              fill: $color-darkGrey;
            }
          }
        }
      }
    }
  }

  &.lightMode {
    .darkmodeSwitch {
      border: 1px solid color lighten($color: $color-darkGrey, $amount: 20);
      @include device-m {
        border-color: $color-darkGrey;
      }
      .knob {
        background-color: lighten($color: $color-darkGrey, $amount: 20);
        transform: translate3d(22px, 0, 0);
        @include device-m {
          transform: translate3d(40px, 0, 0);
          background-color: $color-darkGrey;
        }
      }
      &:hover {
        .knob {
          background-color: lighten($color: $color-darkGrey, $amount: 0);
        }
      }
      .switchIcon {
        &.moon {
          transform: translate3d(-5px, 0, 0);
          opacity: 0;
        }
        &.sun {
          opacity: 1;
          transform: translate3d(0px, 0, 0);
        }
      }
    }
  }
}