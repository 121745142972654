
@mixin device-small {
  @media only screen and (max-width:767px) {
    @content;
  }
}

@mixin device-m {
  @media only screen and (max-width:768px) {
    @content;
  }
}

@mixin device-l {
  @media only screen and (max-width:1080px) {
    @content;
  }
}

@mixin device-xl {
  @media only screen and (max-width:1440px) {
    @content;
  }
}

@mixin type-title-l {
  font-size: $text-size-xl;
  line-height: 1.2em;
  margin: 0 0 $spacing-l 0;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  // transition: color $transition-normal ease-in-out;
  will-change: color;
  @include device-xl {
    font-size: $text-size-xl * 0.8;
  }
  @include device-m {
    font-size: $text-size-xl * 0.6;
  }
}

@mixin type-title-m {
  font-size: $text-size-l;
  line-height: 1.2em;
  margin: 0 0 $spacing-l 0;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  // transition: color $transition-normal ease-in-out;
  will-change: color;
  @include device-xl {
    font-size: $text-size-l * 0.8;
  }
  @include device-m {
    font-size: $text-size-l * 0.6;
  }
}

@mixin type-title-s {
  font-size: $text-size-ml;
  line-height: 2em;
  margin: $spacing-s 0 $spacing-m 0;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  // transition: color $transition-normal ease-in-out;
  will-change: color;
  @include device-xl {
    font-size: $text-size-ml * 0.8;
  }
  @include device-m {
    font-size: $text-size-ml * 0.6;
  }
}

@mixin type-body {
  font-size: $text-size-m;
  line-height: 1.5em;
  margin: 0 0 $spacing-m 0;
  letter-spacing: 0px;
  font-weight: normal;
  // transition: color $transition-normal ease-in-out;
  will-change: color;
  @include device-xl {
    font-size: $text-size-m * 0.8;
  }
  @include device-m {
    font-size: $text-size-s;
  }
}

@mixin type-link {
  font-size: $text-size-s;
  line-height: 1.5em;
  margin: 0;
  letter-spacing: 0px;
  font-weight: 500;
  // transition: color $transition-normal ease-in-out;
  will-change: color;

  @include device-m {
    font-size: $text-size-s * 1.4;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin type-footer {
  font-family: 'DIN Condensed';
  font-size: $text-size-ml;
  font-weight: bold;
  // transition: color $transition-normal ease-in-out;
  will-change: color;
  @include device-m {
    font-size: $text-size-m;
  }
}