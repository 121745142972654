@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 68px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: $spacing-l;
  pointer-events: none;
  @include device-m {
    padding: $spacing-l $spacing-ml;
  }
}

.titleLine,
.titleLine2 {
  position: relative;
  display: inline-block;
  opacity: 0;
  transform: translate3d(0, -20px, 0);
  
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition: opacity 1s $easeInOutCubic #{$i * .1}s, transform 1s $easeOutCubic #{$i * .1}s;
    }
  }

  @include device-m {
    line-height: 1.4em;
    &.mobileTitle {
      font-size: $text-size-xl * 0.4;
      line-height: 1.1em;
    }
  }
}

// .titleLine2 {
//   @for $i from 1 through 6 {
//     &:nth-child(#{$i}) {
//       transition: opacity 1s $easeInOutCubic #{($i * .05) + 0.25}s, transform 1s $easeOutCubic #{($i * .05) + 0.25}s;
//     }
//   }
// }

.show {
  .titleLine,
  .titleLine2 {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}