@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

@keyframes rotate {
  from {
    transform: translate3d(0, 1px, 0) rotate(0deg) scale(0.9);
  }
  to {
    transform: translate3d(0, 1px, 0) rotate(360deg) scale(0.9);
  }
}

.buttonWrapper {
  position: relative;
  width: 240px;
  height: 240px;
  // border: 1px solid white;

  .button {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    border: 1px solid $color-white;
    cursor: pointer;
    // transition: border $transition-normal ease-in-out;
    will-change: border;

    svg, 
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 160px;
      height: 160px;
      margin: -80px 0 0 -80px;
      pointer-events: none;
      &.arrow {
        mix-blend-mode: difference;
      }
      &.cta {
        opacity: 0;
        will-change: transform, opacity;
        transition: opacity $transition-normal $easeInOutCubic;
        animation-duration: 10s;
        animation-name: rotate;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform: scale(0.9)
      }
      line,
      path {
        stroke: $color-white;
        // transition: stroke $transition-normal ease-in-out;
        will-change: stroke;
      }
    }

    .inside {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 80px;
      background-color: $color-white;
      transform-origin: center center;
      transform: scale(0);
      transition: transform $transition-normal $easeOutCubic;
      will-change: transform;
      pointer-events: none;
    }
    
    &:hover {
      .cta {
        transition-delay: $transition-fast;
        opacity: 1;
      }
      .inside {
        transform: scale(1);
      } 
    }
  }
}

:global {
  .is-light-mode {
    :local {
      .buttonWrapper {
        .button {
          border: 1px solid $color-black;
          svg {
            mix-blend-mode: normal;
            line,
            path {
              stroke: $color-black;
            }
          }
        }
      }
    }
  }
}
